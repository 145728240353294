

export const allowedList = [
    "songchao",
    "bingozh",
    "katsua",
    "bakariw",
    "shinkom",
    "jimboyj",
    "jungeric",
    "kenikono",
    "yingan",
    "shinozta",
    "yuminasu",
    "hirof",
    "mfurut",
    "shkosugi",
    "royamada",
    "yamkyok",
    "damanimd",
    "bhaankur",
    "kawtakao",
    "takesuzu",
    "yuton",
    "broughtc",
    "chrhuan",
    "aizhao",
    "syamano",
    "tankan",
    "yanglun",
    "emihagi",
    "houmanti",
    "ahnaabid",
    "qiuyunz",
    "yifeizh",
    "wangful",
    "wanlei",
    "skyliaoo",
    "donglonz",
    "junyrbai",
    "manlei",
    "ppengsu",
    "qiyingz",
    "wihu",
    "dyechua",
    "yyinw",
    "yancyso",
    "cordelle",
    "ceciliau",
    "wunnavam",
    "urmisako",
    "nishinao",
    "sarapike",
    "asukt",
    "hssugie",
    "cmorishi",
    "sonomik",
    "hidehikf",
    "skuwahar",
    "ydaisu",
    "kikucchi",
    "namnakan",
    "mfurut",
    "ynaoyuki",
    "ntakatos",
    "jueohara",
    "rubyccha",
    "cmorishi",
    "sonomik",
    "hidehikf",
    "skuwahar",
    "ydaisu",
    "kikucchi",
    "namnakan",
    "mfurut",
    "woojinoh",
    "yuast",
    "keinaga",
    "yagihito",
    "tschmak",
    "okatakah",
    "tanoaya",
    "knio",
    "hirof",
    "miyazsat",
    "yutast",
    "hjaehun",
    "tktkfumi",
    "knariga",
    "suzumitf",
    "pyuji",
    "marikoma",
    "miasaba",
    "yuytakah",
    "hiroro",
    "hspatric",
    "akkurasa",
    "rkirsty",
    "tnoguchi",
    "oginos",
    "takayoga",
    "uetaniry",
    "michiyod",
    "takaiht",
    "yurimamo",
    "morimori",
    // below is the list of pxt team
    "godmitry", "higashjr", "ogurayok", "tmtaichi", "yukioga", "atsunori", "sakamona", "tagusari", "soichirw",
    "aiiwata", "ynamoto", "muchiyae", "mayudai", "asadarer", "mrkaneko", "hschihar", "taishiyy", "linyixin",
    "hirosaok", "ikedsumi", "nemotoa", "hanaen", "hamaryos", "kosuh", "pying", "sayukara", "nozomir", "kayou",
    "yuzog", "mmotohir", "manroger", "kanekow", "babikovr", "tanmeloy", "tehyt", "yukosano", "junhiro",
    "kumipon", "sakaida", "ehitomi", "dfujiwa", "yuyeha", "mohitay", "deeparma", "tyukimot", "kaorukk",
    "isnaomi", "weilinzh", "juyamad", "iyasuk", "nagarok", "nishinyx", "takahany", "yumiliz", "ogurhir",
    "azuskira", "ehhoriuc", "sakshino", "hosodaeh", "oyauchim", "nkamich", "seiyashi", "izutani", "sastat",
    "rmsmasa", "mumezawa", "yamnobuk", "kitkshio", "koakiko", "mttakay", "yoshieya", "shingoho", "hisayodt",
    "michiyod", "anifuru", "haeryunk", "msakurai", "jungekim", "junkomat", "ktakenor", "caole", "minyusuk",
    "hirokuda", "yukikoba", "kurecml", "chiishii", "akishara", "kotomipe", "badels", "yykana", "itohmich",
    "kimipan", "shuohara", "ryshrysh", "kkurumii", "olgazh", "rmmorimo", "jojikawa", "michinak", "sakataks",
    "kyokai", "weifen", "shinehru", "kondonk", "nakateyu", "hanaza", "noriyta", "wadamari", "risaogaw",
    "yamtimot", "renasim", "yuktam", "nakamaay", "yoshiee", "yukinoeb", "dahaupt", "roblder", "torish",
    "sayaomor", "nakazama", "mizuhoyo", "satomoni", "akasumi", "ayamisa", "hsug", "tankiki", "kisuayak",
    "chihcas", "katetkd", "konamis", "obhiroko", "kentob", "hirotamh", "akkubosh", "ywatts", "yanchenx",
    "okuhim", "jhkeng", "harritsg", "sbmadoka", "maxtwill", "kawyukie", "tomoim", "iizumi", "kuroseau",
    "fabreb", "aromigh", "lisakom", "mnagaha", "esakat", "wunnavam", "kimeday", "shinoh", "yuichixi",
    "nomiri", "satokoam", "takayoga", "midoriu", "yuokamur", "hiromita", "mitakash", "masoyam", "senoo",
    "kobkota", "haruikui", "ayaina", "kurist", "tserendo", "erikona", "ahiranok", "misahata", "leokim",
    "ikenji", "affuruka", "koishuns", "enaoko", "salisann", "takuyatt", "mihosug", "mnaruto", "toshiga",
    "hiromask", "tamkanak", "yskes", "sjinno", "tnishizk", "aescalad", "kyammayu", "shimiari", "nanaoka",
    "yoshiir", "hrksakai", "junkoda", "wuyuwen", "kentkgw", "bingyus", "rsshimiz", "asukt", "mkkawano",
    "itoakiko", "ktomoka", "yuisasa", "tsury", "tomyosh", "yujnakas", "sayaktak", "klaarisa", "mariesuz",
    "yukikmae", "kismiho", "nakayyu", "koiju", "itorin", "emfuruk", "abmisono", "ysakain", "satmaik",
    "hongasak", "yuichikk", "chikkuma", "tommyaug", "yuyasakg", "kminamib", "etsumats", "maetomot",
    "undrrbaa", "syoshinh", "fkotaroo", "oktaiki", "jikuoka", "sadwatar", "tsujwata", "daamatsu",
    "naokomoz", "gunshar", "tomyamg", "yukisnow", "shunfuj", "kumaimi", "fujiemim", "kasaimin", "hohomik",
    "asaktakl", "rnakazut", "katsund", "kashire", "pmokeita", "tanomomo", "songyanv", "rhariu", "misakamj",
    "nakazato", "onomiori", "seigon", "ykoura", "sarhaba", "ksatomi", "snnishik", "ishiyosu", "cnagata",
    "yuitaka", "sekigat", "yazerina", "shiomik", "shiroham", "masuimeg", "yukfujih", "asukaki", "machic",
    "otsukake", "ogawayur", "ynnkani", "narimasu", "skakihat", "toasakaw", "terfmami", "shinobsd", "hayayums",
    "zyukikos", "watameg", "ozawayta", "takeichy", "vhuong", "mizuhoht", "erikatoo", "matsukek", "sihiromi",
    "arisaokm", "ohnatsu", "vyoshii", "izuka", "tomomsat", "miktagam", "qizhengy", "etsuyoh", "namyamak",
    "ndbrock", "zchambz", "mcchugen", "hpark", "saoringo", "prsmita", "keikooba", "harakens", "atanim",
    "ssuto", "tharperx", "ishigujt", "wkswan", "rumikos", "miyuya", "sukechi", "sakaben", "ayuyos",
    "monamio", "zmabbott", "koniyu", "laurdes", "mntaki", "jenidh", "naokued", "sawami", "onoriko",
    "yamminam", "roohshi", "swakako", "ktok", "ichmakik", "jemmalg", "baicong", "nicxjack", "sandoasu",
    "yubirk", "shizk", "chuerica", "kaiqinyi", "xyhe", "rossrhm", "geoffcc", "urmisako", "ayakono",
    "takeber", "sakikat", "kuwayama", "ykaki", "takyayoi", "ukaori", "kiranmyi", "yukitama", "joohoon",
    "kvknm", "iryoko", "chihis", "minamiza", "ynorie", "uetaniry", "jjjarloy", "montamk", "takas",
    "ayakane", "gkatsuki",
    // below is list of softline
    "kytaka", "saekm", "namiwtnb", "sakitkym", "asamiko", "hosodae", "jimpeigo", "matsunon", "kumeryok", "kanamoar",
    "kurihhik", "iwataf", "moritah", "natfuji", "tomoh", "amiyakke", "takhoshi", "sakaliu", "rikakoo", "natsutsu",
    "chiefu", "asukaid", "yujiinab", "hrokii", "rikof", "jiyunmoo", "shiizumi", "takesuzu", "tankan", "aoyas",
    "jimboyj", "takikb", "vwadahik", "tsunehii", "morsayak", "shinozta", "hnitta", "masumin", "akatsua", "damanimd",
    "rubyccha", "aymsato", "jiajiw", "jungeric", "minegit", "syamano", "yuton", "chrhuan", "retoyoda", "kahonaka",
    "skawamor", "ymmizusa", "tsutomo", "yuminasu", "fujitan", "miyaucr", "mhama", "suimo", "kenikono", "broughtc",
    "yanglun", "tiichika", "smizuha", "fumnis", "morigakf", "bhaankur", "aizhao", "shimasak", "kawtakao", "kamiryo",
    "kanakos", "kuchizaw", "muuchino", "bakariw", "yumiiuch", "shinkom", "essasaki", "kozack", "roohyama", "osamuo",
    "takuyahs", "nanambab", "nshinho", "inomiyu", "skitayam", "fksmtkm", "kananh", "daiots", "izurina", "ynobuko",
    "jumorimo", "rynagano", "miytokud", "matsryu", "nishinao", "sarapike", "jhickl", "longato", "kentakeu", "hasome",
    "atanim", "kingjn", "ayakaama", "kuowe", "jfurusaw", "lelands", "yyano", "omori", "bossom", "iskwmre", "pfwryan",
    "mahoinou",
    "akihisa",
    "ayane",
    "ryysh",
    "tomokusa",
    "ryotakom",
    "cheleung",
    "hondamas",
    "yasuti",
    "ksskmo",
    "yoshiu",
    "aiisogai",
    "morimiho",
    "garyu",
    "jccch",
    "ayakono",
    "kazuita",
    "ohnuma",
    "yksakamo",
    "sakikat",
    "kenichib",
    "pmokeita",
    "nobo",
    "mmryag",
    "mcgarreb",
    "hiroshin",
    "hyunjik",
    "tyukimot",
    "terumah",
    "tomomina",
    "chiatsai",
    "mllizak",
    "emadoka",
    "jongkeun",
    "ksugawke",
    "kyokorta",
    "conoda",
    "yasutana",
    "takukawa",
    "omasak",
    "akihiroa",
    "sayahino",
    "nagatahr",
    "haraaki",
    "bleustei",
    "kinriku",
    "atabuchi",
    "pilio",
    "ayakon",
    "slimpic",
    "tpmasaki",
    "yyano",
    "takishii",
    "lelands",
    "wangdali",
    "liuzhenz",
    "kfukushi",
    "suhasas",
    "liliyan",
    "kwanjin",
    "sherrwan",
    "sonxiaoy",
    "wenyan",
    "trepperp",
    "yanyueha",
    "sjaelee",
    "harafuji",
    "zhenzhen"


]